<template>
    <v-container fluid class="pt-0 mr-10 ml-10">
        <v-layout row wrap>
            <v-col cols="9">
                <!-- Page view -->
                <router-view></router-view>
                <!-- end -->
            </v-col>
            <v-col cols="3">
                <RightMenu />
            </v-col>
        </v-layout>

        <!-- Success -->
        <SuccessMessage />
        <!-- end -->

    </v-container>
</template>

<script>
  export default {
    name: 'Keys',
    created() {
      if(this.$route.matched.length === 1){
        this.$router.push(this.$router.options.routes.find(item => item.path === this.$route.matched[0].path).children[0].path)
      }
    }
  }
</script>
